<template>
	<ValidationProvider
		v-slot="{ classes, errors }"
		ref="unitWage"
		:name="`unit-wage-${id}`"
		rules="isNumber"
	>
		<div :class="['col-12', classes]">
			<input
				:id="`unit-wage-${id}`"
				v-model="unitWage"
				type="text"
				class="form-control unit-wage"
				@keyup="handleUnitWage"
				@paste="handleOnPaste($event)"
				@keydown="checkValidPhone($event)"
			/>
			<div v-if="errors.length" class="error-mess">
				{{ errors[0] }}
			</div>
		</div>
	</ValidationProvider>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { SET_UNIT_WAGE } from "../store/mutation-types"
const { mapMutations, mapState } = createNamespacedHelpers("paysheet")
export default {
	name: "UnitWageItem",
	props: {
		id: {
			type: [Number, String],
			default: null,
		},
		type: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			unitWage: 0,
		}
	},
	computed: {
		...mapState(["typeOrder"]),
	},
	created() {
		if (this.typeOrder?.length) {
			this.typeOrder.forEach(el => {
				if (el.id === this.id) {
					this.unitWage = el.cost
				}
			})
		}
	},
	methods: {
		...mapMutations({ SET_UNIT_WAGE }),
		async handleUnitWage() {
			const { valid } = await this.$refs.unitWage.validate()

			if (!valid) return

			const payload = {
				id: this.id,
				cost: this.unitWage ? parseInt(this.unitWage) : 0,
			}
			this.SET_UNIT_WAGE(payload)
		},
	},
}
</script>
<style lang="scss">
.unit-wage {
	max-width: 120px;
	margin: auto;
	min-width: 70px;
	text-align: center;
}
</style>
